//
// Gatsby Tinongo Home page
// Route: /
//

import React, { Component } from 'react';
import { graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { html } from '../utils';

import AppShell from '../components/app-shell';
import Button from '../components/button';
import Grid from '../components/grid';
import GridRow from '../components/grid-row';
import GridItem from '../components/grid-item';
import Hero from '../components/hero';
import Section from '../components/section';
import SEO from '../components/seo';

import styles from './index.module.scss';

/* __<Form> Component__ */
class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      einzelMannschaftssport: '',
      indoorOutdoor: '',
      eintrittsalter: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    navigate('/suche/sportarten', {
      state: this.state,
    });
  }

  render() {
    return (
      <form>
        <fieldset>
          <label>
            <span className={styles.fieldLabel}>
              Einzel- oder Mannschaftssport:
            </span>
            <select
              key="einzelMannschaftssport"
              name="einzelMannschaftssport"
              value={this.state.einzelMannschaftssport}
              onChange={this.handleChange}
              onBlur={this.handleChange}>
              <option vlaue="">--- Bitte wählen ---</option>
              <option value="Einzelsport">Einzelsport</option>
              <option value="Mannschaftssport">Mannschaftssport</option>
            </select>
          </label>
        </fieldset>

        <fieldset>
          <label>
            <span className={styles.fieldLabel}>Indoor- oder Outdoor:</span>
            <select
              key="indoorOutdoor"
              name="indoorOutdoor"
              value={this.state.indoorOutdoor}
              onChange={this.handleChange}
              onBlur={this.handleChange}>
              <option value="">--- Bitte wählen ---</option>
              <option value="Indoor">Indoor</option>
              <option value="Outdoor">Outdoor</option>
            </select>
          </label>
        </fieldset>

        {/* <label>
          Geeignet ab:
          <select
            key="eintrittsalter"
            name="eintrittsalter"
            value={this.state.eintrittsalter}
            onChange={this.handleChange}
          >
            <option>--- Bitte wählen ---</option>
            <option value="4">&lt;= 4 Jahren</option>
            <option value="5">5 Jahren</option>
            <option value="6">6 Jahren</option>
            <option value="7">7 Jahren</option>
            <option value="8">8 Jahren</option>
            <option value="9">9 Jahren</option>
            <option value="10">10 Jahren</option>
            <option value="11">11 Jahren</option>
            <option value="12">12 Jahren</option>
            <option value="13">13 Jahren</option>
            <option value="14">&gt;= 14 Jahren</option>

          </select>
        </label> */}

        <fieldset>
          <Button raised="true" block="true" onClick={this.handleSubmit}>
            Jetzt Sportart suchen
          </Button>
        </fieldset>
      </form>
    );
  }
}

export default function Index({ data }) {
  const heroClaim = [
    { line: 1, text: data.contentfulHomePage.heroSloganLine1 },
    { line: 2, text: data.contentfulHomePage.heroSloganLine2 },
  ];

  const hero = (
    <Hero claim={heroClaim} sliderImages={data.contentfulHomePage.heroSlider} />
  );

  const search = (
    <div className={styles.search}>
      <div className={styles.searchInner}>
        <Form />
      </div>
    </div>
  );

  const intro = (
    <Section fullWidth>
      <div className={styles.intro}>
        <div className={styles.introContent}>
          <p className={styles.introContentText}>
            {data.contentfulHomePage.intro.intro}
          </p>
        </div>
        <div
          className={styles.introBlog}
          dangerouslySetInnerHTML={html(
            data.contentfulHomePage.blogNews.blogNews
          )}
        />
      </div>
    </Section>
  );

  const sportTiles = data.allContentfulSportart.nodes.map((el, idx) => {
    return (
      el.image && (
        <GridItem key={el.id} style={{ order: idx + 1 }}>
          <Link to={`/sportart/${el.slug}`} className={styles.tile}>
            <Img
              key={el.image.id}
              fluid={el.image.fluid}
              alt={el.titel}
              style={{
                backgroundColor: '#000',
              }}
              objectFit="contain"
              objectPosition="50% 50%"
            />
            <h3 className={styles.tileLabel}>
              <span className={styles.tileLabelLine}>
                <b className={styles.tileLabelText}>{el.titel}</b>
              </span>
            </h3>
          </Link>
        </GridItem>
      )
    );
  });

  const sportTilesGrid = (
    <div>
      <Grid>
        <GridRow
          tiles
          xsmallCols={2}
          smallCols={2}
          mediumCols={3}
          largeCols={4}
          xlargeCols={6}>
          {sportTiles}
        </GridRow>
      </Grid>
    </div>
  );

  return (
    <>
      <SEO
        title="Startseite"
        description="Tinongo hilft Eltern neutral und unabhängig, die wichtigsten Fragen bei der Sportwahl für ihre Kinder zu beantworten."
      />
      <AppShell>
        {hero}
        {intro}
        {search}
        {sportTilesGrid}
      </AppShell>
    </>
  );
}

export const query = graphql`
  query {
    contentfulHomePage {
      heroSlider {
        titel
        image {
          id
          fluid(maxWidth: 1800, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      heroSloganLine1
      heroSloganLine2
      intro {
        intro
      }
      blogNews {
        blogNews
      }
    }
    allContentfulSportart(sort: { fields: titel, order: ASC }) {
      nodes {
        id
        titel
        slug
        image {
          id
          fluid(maxWidth: 550, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

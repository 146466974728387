/* React Tinongo <GridItem> component */

import React from 'react';
import classNames from 'classnames';

import styles from './grid-item.module.scss';

/* <GridItem> component */
function GridItem(props) {
  const classes = classNames({
    [`${styles.gridItem}`]: true,
    [`${styles.gridItemDark}`]: props.dark,
    [`${styles.gridItemLight}`]: props.light,
  });

  return (
    <div className={classes} style={props.style}>
      {props.children}
    </div>
  );
}

export default GridItem;

/* React Tinongo <Grid> component */

import React from 'react';

import styles from './grid.module.scss';

/* <Grid> component */
function Grid(props) {
  return <div className={styles.grid}>{props.children}</div>;
}

export default Grid;

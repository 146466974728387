/* React Tinongo <GridRow> component */

import React from 'react';
import classNames from 'classnames';

import styles from './grid-row.module.scss';

/* <GridRow> component */
function GridRow(props) {
  const classes = classNames({
    [`${styles.gridRowDefault}`]: !props.tiles,
    [`${styles.gridRowTiles}`]: props.tiles,

    // xsmall
    [`${styles.gridRowXsOneCol}`]: props.xsmallCols && props.xsmallCols === 1,
    [`${styles.gridRowXsTwoCols}`]: props.xsmallCols && props.xsmallCols === 2,

    // small
    [`${styles.gridRowSOneCol}`]: props.smallCols && props.smallCols === 1,
    [`${styles.gridRowSTwoCols}`]: props.smallCols && props.smallCols === 2,

    // medium
    [`${styles.gridRowMOneCol}`]: props.mediumCols && props.mediumCols === 1,
    [`${styles.gridRowMTwoCols}`]: props.mediumCols && props.mediumCols === 2,
    [`${styles.gridRowMThreeCols}`]: props.mediumCols && props.mediumCols === 3,
    [`${styles.gridRowMFourCols}`]: props.mediumCols && props.mediumCols === 4,

    // large
    [`${styles.gridRowLOneCol}`]: props.largeCols && props.largeCols === 1,
    [`${styles.gridRowLTwoCols}`]: props.largeCols && props.largeCols === 2,
    [`${styles.gridRowLThreeCols}`]: props.largeCols && props.largeCols === 3,
    [`${styles.gridRowLFourCols}`]: props.largeCols && props.largeCols === 4,

    // xlarge
    [`${styles.gridRowXlOneCol}`]: props.xlargeCols && props.xlargeCols === 1,
    [`${styles.gridRowXlTwoCols}`]: props.xlargeCols && props.xlargeCols === 2,
    [`${styles.gridRowXlThreeCols}`]:
      props.xlargeCols && props.xlargeCols === 3,
    [`${styles.gridRowXlFourCols}`]: props.xlargeCols && props.xlargeCols === 4,
    [`${styles.gridRowXlFiveCols}`]: props.xlargeCols && props.xlargeCols === 5,
    [`${styles.gridRowXlSixCols}`]: props.xlargeCols && props.xlargeCols === 6,
  });

  return (
    <div className={classes} style={props.style}>
      {props.children}
    </div>
  );
}

export default GridRow;

/*  React Tinongo <Hero> component */

import React from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';

import styles from './hero.module.scss';

/* <Hero> component */
function Hero(props) {
  const images = props.sliderImages.map((el) => (
    <Img
      key={el.image.id}
      fluid={el.image.fluid}
      alt={el.titel}
      objectFit="cover"
      objectPosition="50% 50%"
    />
  ));

  const claim = props.claim.map((claim, idx) => (
    <span className={styles.heroClaimLine} key={idx}>
      <b className={styles.heroClaimText}>{claim.text}</b>
    </span>
  ));

  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 2000,
    fade: true,
    swipe: false,
    touchMove: false,
    draggable: false,
    pauseOnHover: false,
  };

  return (
    <header className={styles.hero}>
      <Slider {...sliderSettings}>{images}</Slider>
      <h1 className={styles.heroClaim}>{claim}</h1>
    </header>
  );
}

export default Hero;
